import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectSubsis (Vue, filter, search, sorter, page, idsistema) {
    const apiFilter = new APIFilter()
      .addExact('idsistema', idsistema)
      .addGT('estado', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('descripcion', search)
        .addILike('codigo', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    const resp = await Vue.$api.call(
      'subsis.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSubsisRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsubsis', pks)
    const resp = await Vue.$api.call('subsis.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
